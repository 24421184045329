import NextAuth, {Account, DefaultSession, Session} from "next-auth"
import MicrosoftEntraID, {MicrosoftEntraIDProfile} from "@auth/core/providers/microsoft-entra-id"
import Atlassian from "@auth/core/providers/atlassian";
import apiClient from "@/services/api/apiClient";

declare module "@auth/core/providers/microsoft-entra-id" {
    interface MicrosoftEntraIDProfile {
        roles?: string[];
    }
}
declare module "@auth/core/jwt" {
    /** Returned by the `jwt` callback and `auth`, when using JWT sessions */
    interface JWT {
        /** OpenID ID Token */
        roles: string[];
        id_token: string;
        expires_at?: number;
        refresh_token?: string;
    }
}

declare module "next-auth" {
    /**
     * Returned by `auth`, `useSession`, `getSession` and received as a prop on the `SessionProvider` React Context
     */
    interface Session {
        id_token: string;
        user: User;
        expires_at?: number;
    }

    interface User {
        roles: string[];
    }

    interface Account {
        id_token: string;
    }
}
export const {handlers, signIn, signOut, auth} = NextAuth({
    providers: [MicrosoftEntraID({
        clientId: process.env.AUTH_MICROSOFT_ENTRA_ID_ID,
        clientSecret: process.env.AUTH_MICROSOFT_ENTRA_ID_SECRET,
        issuer: `https://login.microsoftonline.com/${process.env.AUTH_MICROSOFT_ENTRA_ID_TENANT_ID}/v2.0`,
        profile: (prof: MicrosoftEntraIDProfile, t) => {
            if (!prof.roles || prof.roles.length === 0) {
                throw new Error("Access denied");
            }
            return { roles: prof.roles, ...prof };
        },
        authorization: {
            params: {
                scope: `openid profile email ${process.env.AUTH_MICROSOFT_ENTRA_ID_ID}/.default offline_access`,
            },
        },
    })],
    session: {
        strategy: "jwt"
    },
    pages: {
        error: '/error',
    },
    callbacks: {
        async signIn({ user }) {
            try {
                return !(!user.roles || user.roles.length === 0);
            } catch (error) {
                return false;
            }
        },
        async jwt({token, user, account}) {
            if (user) { // User is available during sign-in
                token.roles = user.roles
            }
            if (account) { // Available only during sign-in
                token.id_token = account.id_token;
                token.refresh_token = account.refresh_token;
                token.expires_at = account.expires_at;
                await apiClient.postNewUser(token.id_token);
            }
            if (token.expires_at && Date.now() < token.expires_at * 1000) {
                return token;
            }
            if (token.refresh_token) {
                try {
                    const url = `https://login.microsoftonline.com/${process.env.AUTH_MICROSOFT_ENTRA_ID_TENANT_ID}/oauth2/v2.0/token`;
                    console.log("Trying to refresh token")
                    const response = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: new URLSearchParams({
                            client_id: process.env.AUTH_MICROSOFT_ENTRA_ID_ID!,
                            client_secret: process.env.AUTH_MICROSOFT_ENTRA_ID_SECRET!,
                            grant_type: "refresh_token",
                            refresh_token: token.refresh_token,
                        }),
                    });
                    const refreshedTokens = await response.json();
                    if (!response.ok) throw refreshedTokens;
                    token.id_token = refreshedTokens.id_token;
                    token.refresh_token =
                        refreshedTokens.refresh_token ?? token.refresh_token;
                    token.expires_at = Math.floor(Date.now() / 1000) + refreshedTokens.expires_in;
                    console.log("Token refreshed")
                } catch (error) {
                    console.error("Error refreshing access token", error);
                    token.refresh_token = undefined;
                }
            }
            return token;
        },
        async session({session, token}) {
            session.id_token = token.id_token;
            session.user.roles = token.roles;
            if (token.expires_at) {
                session.expires_at = token.expires_at;
            }
            return session
        },
    },

    trustHost: true,
})