import {API_CONFIG} from "@/constants/apiConstants";
import {getSession} from "next-auth/react";
import {auth} from "@/auth";
import {Chat} from "@/services/models/chat";
import {getMachineId} from "@/store/features/widget/baseWidgetQuery";
import {StreamingResponse} from "@/services/models/streaming-reponse";

export interface ApiResponse<T> {
    data: T | null;
    error: string | null;
    status: number;
}

export interface ApiConfig {
    defaultHeaders?: HeadersInit;
    timeout?: number;
}

export interface UploadProgressCallback {
    (progress: number): void;
}

export interface PostMessageOptions {
    onChunk?: (chunk: StreamingResponse) => void;
    onFinished?: (message: StreamingResponse) => void;
}

export class ApiClient {
    private readonly defaultHeaders: HeadersInit;
    private readonly timeout: number;

    constructor(config: ApiConfig) {
        this.defaultHeaders = config.defaultHeaders || {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        this.timeout = config.timeout || 30000;
    }

    public get baseURL() {
        return API_CONFIG.BASE_URL.API_URL;
    }

    private async handleResponse(response: Response): Promise<Response> {
        if (response.status === 401) {
            window.location.href = '/login?error=session_expired';
            throw new Error('Session expired');
        }
        return response;
    }

    public async logChunksAndReturnFullResponse(endpoint: string, message: string, agent: string, options?: PostMessageOptions, signal?: AbortSignal, isWidget: boolean = false): Promise<string> {
        const response = await this.queryChat(endpoint, message, agent, signal, isWidget);
        return await this.processStreamingResponse(response, options);
    }

    private async fetchWithAuth(
        url: string,
        options: RequestInit,
        useMachineAuth: boolean = false
    ): Promise<Response> {
        const headers = useMachineAuth
            ? await this.getMachineAuthHeaders()
            : await this.getAuthHeaders();
        const response = await this.fetchWithTimeout(url, {
            ...options,
            headers: {
                ...headers,
                ...options.headers,
            },
        });
        return this.handleResponse(response);
    }

    private async getMachineAuthHeaders(): Promise<HeadersInit> {
        const machineId = await getMachineId();
        const headers: Record<string, string> = {...this.defaultHeaders as Record<string, string>};

        if (machineId) {
            headers['Authorization'] = `Bearer ${machineId}`;
        }

        return headers;
    }

    public async queryChat(endpoint: string, message: string, agent: string, signal?: AbortSignal, isWidget: boolean = false): Promise<Response> {
        const url = isWidget
            ? `${this.baseURL}${API_CONFIG.ENDPOINTS.CHAT.WIDGET_CHAT}`
            : `${this.baseURL}${API_CONFIG.ENDPOINTS.CHAT.SEND_MESSAGE}${endpoint}`;
        const response = await this.fetchWithAuth(url, {
            signal,
            method: 'POST',
            body: JSON.stringify({
                message,
                agent
            })
        }, isWidget);
        return response;
    }

    public async processStreamingResponse(response: Response, options?: PostMessageOptions): Promise<string> {
        const reader = response.body?.getReader();
        if (!reader) return '';

        const decoder = new TextDecoder();
        let done = false;
        let fullResponse = '';
        let messageId: number | undefined;

        while (!done) {
            const { value, done: isDone } = await reader.read();
            done = isDone;

            if (value) {
                const chunkText = decoder.decode(value);

                try {
                    let jsonArrayStr = chunkText.replace(/}{/g, "},{");
                    jsonArrayStr = `[${jsonArrayStr}]`;

                    const jsonArray = JSON.parse(jsonArrayStr);

                    for (const obj of jsonArray) {
                        if (obj.messageId !== undefined && obj.messageId > 0) {
                            messageId = obj.messageId;
                        }

                        if (obj.message) {
                            console.log('Message:', obj.message);
                            if (options?.onChunk) {
                                options.onChunk(obj);
                            }
                            fullResponse += obj.message;
                        }
                    }
                } catch (error) {
                    console.error('Error processing chunk:', error);
                    if (options?.onChunk) {
                        options.onChunk({message: fullResponse});
                    }
                    fullResponse += chunkText;
                }
            }
        }
        if (options?.onFinished) {
            options.onFinished({message: fullResponse, messageId: messageId });
        }

        return fullResponse;
    }


    public async createNewChat(projectId: string, signal?: AbortSignal): Promise<Chat> {
        const url = `${this.baseURL}${API_CONFIG.ENDPOINTS.CHAT.NEW_CHAT}`;
        const response = await this.fetchWithAuth(url, {
            signal,
            method: 'POST',
            body: JSON.stringify({projectId})
        });

        if (!response.ok) {
            throw new Error(`Failed to create chat: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    }

    public async deleteChatFile(collectionId: string, fileId: string): Promise<void> {
        const url = `${this.baseURL}${API_CONFIG.ENDPOINTS.FILE.DELETE_CHAT_FILE}${collectionId}/${fileId}`;
        try {
            const response = await this.fetchWithAuth(url, {
                method: 'DELETE'
            });

            if (!response.ok) {
                console.log(`Failed to delete file: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    }

    public async postNewUser(token: string): Promise<void> {
        const url = `${this.baseURL}${API_CONFIG.ENDPOINTS.USER.NEW_USER}`;
        const headers: Record<string, string> = {...this.defaultHeaders as Record<string, string>};
        headers['Authorization'] = `Bearer ${token}`;

        const response = await fetch(url, {
            method: 'POST',
            headers
        });

        if (response.status !== 200) {
            console.log(`Error: ${response.status} - ${response.statusText}`);
        }
    }

    private async fetchWithTimeout(
        url: string,
        options: RequestInit
    ): Promise<Response> {
        const signals = [AbortSignal.timeout(this.timeout)]
        if (options.signal)
            signals.push(options.signal)
        try {
            return await fetch(url, {
                ...options,
                signal: AbortSignal.any(signals),
            });
        } finally {
        }
    }

    private async getAuthHeaders(server?: boolean): Promise<HeadersInit> {
        const session = server ? await auth() : await getSession();
        const headers: Record<string, string> = {...this.defaultHeaders as Record<string, string>};

        if (session?.id_token) {
            headers['Authorization'] = `Bearer ${session.id_token}`;
        }

        return headers;
    }

    private async request<T>(
        endpoint: string,
        options: RequestInit = {}
    ): Promise<ApiResponse<T>> {
        const url = `${this.baseURL}${endpoint}`;

        try {
            const response = await this.fetchWithAuth(url, options);

            if (!response.ok) {
                let errorMessage: string;
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.message || `HTTP error! status: ${response.status}`;
                } catch {
                    errorMessage = `HTTP error! status: ${response.status}`;
                }

                return {
                    data: null,
                    error: errorMessage,
                    status: response.status,
                };
            }

            const data = await response.json();
            return {
                data,
                error: null,
                status: response.status,
            };
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
            return {
                data: null,
                error: errorMessage,
                status: 0,
            };
        }
    }

    async get<T>(endpoint: string, options: RequestInit = {}): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            ...options,
            method: 'GET',
        });
    }

    async post<T>(
        endpoint: string,
        data?: any,
        options: RequestInit = {}
    ): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            ...options,
            method: 'POST',
            body: data ? JSON.stringify(data) : undefined,
        });
    }

    async put<T>(
        endpoint: string,
        data?: any,
        options: RequestInit = {}
    ): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            ...options,
            method: 'PUT',
            body: data ? JSON.stringify(data) : undefined,
        });
    }

    async delete<T>(endpoint: string, options: RequestInit = {}): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            ...options,
            method: 'DELETE',
        });
    }
}

const apiClient = new ApiClient({
    defaultHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 30000,
});

export default apiClient;