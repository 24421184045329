import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@/store/store';
import {Chat} from "@/services/models/chat";
import {ChatWithProjectResponse} from "@/services/models/chatWithProjectResponse";
import {Project} from "@/services/models/project";

interface ChatUiSlice {
    currentMessage: string;
    chat?: Chat
    project: Project | null;
}

const initialState: ChatUiSlice = {
    project: null,
    currentMessage: ''
};

export const chatUISlice = createSlice({
    name: 'chatUI',
    initialState,
    reducers: {
        startChat: () => {
        },
        setCurrentMessage: (state, {payload}: PayloadAction<string>) => {
            state.currentMessage = payload;
        },
        resetChatUI: () => {
            return initialState;
        },
        setChat: (state, {payload}: PayloadAction<ChatWithProjectResponse>) => {
            return {
                ...state,
                chat: payload.chat,
                project: payload.project,
            }
        },
        addUserMessages: (state, {payload}: PayloadAction<string>) => {
            if (!state.chat) {
                return;
            }
            const lastNumber = state.chat.messages.length > 0 ? state.chat.messages[state.chat.messages.length - 1].id : 0;
            state.chat.messages.push(
                {
                    message: payload,
                    id: lastNumber + 1,
                    session_id: state.chat.collection_id,
                    type: 'user',
                    time: new Date().toUTCString(),
                    vote: 0
                }
            )
            state.chat.messages.push(
                {
                    message: '',
                    id: -1,
                    session_id: state.chat.collection_id,
                    type: 'ai',
                    time: new Date().toUTCString(),
                    isLoading: true,
                    vote: 0
                }
            )
        },
        addMessageChunk: (state, {payload}: PayloadAction<string>) => {
            if (!state.chat) {
                return;
            }
            const last = state.chat.messages[state.chat.messages.length - 1];
            last.message += payload;
            last.isLoading = false;
        },
        finishAiMessage: (state, {payload}: PayloadAction<number | undefined>) => {
            if (!state.chat) {
                return;
            }
            const last = state.chat.messages[state.chat.messages.length - 1];

            if (payload !== undefined) {
                last.id = payload;
            }
            state.currentMessage = '';
        },
        resetAiMessageError: (state) => {
            if (!state.chat) {
                return;
            }
            const last = state.chat.messages[state.chat.messages.length - 1];
            if (last && last.message.length > 0) {
                //TODO: Add the error on the ai message??
                state.chat.messages.splice(0, state.chat.messages.length - 1); // Remove the last message, the llm has started streaming.
            } else {
                //Nothing was written, Probably a react strictMode abort controller
                state.chat.messages.splice(0, state.chat.messages.length - 2); // Remove the last message, the llm has started streaming.
            }
        }
    }
});

const selectCurrentMessage = (state: RootState) => state.chatUI.currentMessage;
const selectChat = (state: RootState) => state.chatUI.chat;
const selectMessages = createSelector(
    [selectChat], // Input selectors
    (chat) => chat?.messages ?? [] // Output selector logic
);
const isLoading = createSelector(
    [selectMessages],
    (messages) => messages.find(m => m.isLoading) !== undefined
)
export const chatUiSliceSelectors = {
    selectCurrentMessage,
    selectChat,
    selectMessages,
    isLoading
};
export const chatUISliceActions = chatUISlice.actions;
export default chatUISlice.reducer;