import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react'
import {API_CONFIG} from '@/constants/apiConstants'
import {getSession} from 'next-auth/react'
import {auth} from "@/auth";

export const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: API_CONFIG.BASE_URL.API_URL,
    prepareHeaders: async (headers) => {
        const session = await getSession()
        if (session?.id_token) {
            headers.set('authorization', `Bearer ${session.id_token}`)
        }
        return headers
    },
})
export const baseQueryWithReAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQueryWithAuth(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // try to get a new token
        const refreshResult = await auth()
        if (refreshResult) {
            // store the new token
            // api.dispatch(tokenReceived(refreshResult.data))
            // retry the initial query
            result = await baseQueryWithAuth(args, api, extraOptions)
        } else {
            // api.dispatch(loggedOut())
        }
    }
    return result
}

