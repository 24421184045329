import {createApi} from '@reduxjs/toolkit/query/react'
import {ChatHistory} from "@/services/models/chatHistory"
import {baseQueryWithReAuth} from "@/store/features/baseRtkQuery";
import {chatUISliceActions} from "@/store/features/chat/chatUiSlice";
import {ProjectCreationResponse} from "@/services/models/projectCreationResponse";
import {ChatWithProjectResponse} from "@/services/models/chatWithProjectResponse";
import apiClient from "@/services/api/apiClient";

export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['History', 'Messages'],
    endpoints: (builder) => ({
        getChatHistory: builder.query<ChatHistory[], string>({
            query: (projectId) => `Chat/latest/${projectId}`,
            providesTags: ['History']
        }),
        getProjectById: builder.mutation<ProjectCreationResponse, string>({
            query: (projectId) => ({
                url: `Project/${projectId}`,
                method: 'GET'
            }),
        }),
        postVote: builder.mutation<void, { messageId: number; vote: number }>({
            query: ({messageId, vote}) => ({
                url: `Chat/${messageId}/vote`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(vote)
            }),
        }),
        getChatMessages: builder.query<ChatWithProjectResponse, string>({
            query: (uid) => `Chat/${uid}`,
            providesTags: ['Messages'],
            onQueryStarted: async (id, {queryFulfilled, dispatch}) => {
                try {
                    const {data} = await queryFulfilled
                    dispatch(chatUISliceActions.setChat(data))
                    // `onSuccess` side-effect
                    // dispatch(messageCreated('Posts received!'))
                } catch (err) {
                    // `onError` side-effect
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            }
        }),
        postNewMessage: builder.mutation<string, { chatId: string, agent: string, message: string }>({
            queryFn: async ({message, chatId, agent}, {endpoint, dispatch, signal}) => {
                try {
                    dispatch(chatUISliceActions.addUserMessages(message));
                    await apiClient.logChunksAndReturnFullResponse(
                        chatId,
                        message,
                        agent,
                        {
                            onChunk: chunk => {
                                dispatch(chatUISliceActions.addMessageChunk(chunk.message));
                            },
                            onFinished: (response) => {
                                dispatch(chatUISliceActions.finishAiMessage(response.messageId));
                            },
                        },
                        signal
                    );
                    return {data: ""};
                } catch (error) {
                    dispatch(chatUISliceActions.resetAiMessageError());
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            data: error instanceof Error ? error.message : 'An unknown error occurred',
                            error: 'API Error'
                        }
                    };
                }
            }
        })
    }),
})
const {
    useGetChatHistoryQuery,
    useGetChatMessagesQuery,
    usePostVoteMutation,
    useGetProjectByIdMutation,
    usePostNewMessageMutation
} = chatApi
export const chatApiActions = {
    useGetChatHistoryQuery,
    useGetChatMessagesQuery,
    usePostVoteMutation,
    useGetProjectByIdMutation,
    usePostNewMessageMutation
}